/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Footer from "components/Footer/Footer.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";

const useStyles = makeStyles(styles);

export default function SectionFooter() {
    const classes = useStyles();
    return (
        <Footer
            content={
                <div>
                    <div className={classes.left}>
                        <a
                            href="/product-list"
                            className={classes.footerBrand}
                        >
                            Bangles Org
                        </a>
                    </div>
                    <div className={classes.left}>
                        <List className={classes.list}>
                            {/* <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/product-list"
                                    // target="_blank"
                                    className={classes.block}
                                >
                                    Online Product
                                </a>
                            </ListItem> */}
                            {/* <ListItem className={classes.inlineBlock}>
                                    <a
                                        href="/old-product-list"
                                        // target="_blank"
                                        className={classes.block}
                                    >
                                        Old Online Product 
                                    </a>
                                </ListItem> */}
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/bangles-making"
                                    // target="_blank"
                                    className={classes.block}
                                >
                                    Making charge
                                </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/bangles-size-guide"
                                    className={classes.block}
                                >
                                    Bangles Size Chart
                                </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/about-us"
                                    // target="_blank"
                                    className={classes.block}
                                >
                                    About us
                                </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/privacyPolicy"
                                    // target="_blank"
                                    className={classes.block}
                                >
                                    Privacy Policy
                                </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/terms-and-conditions"
                                    // target="_blank"
                                    className={classes.block}
                                >
                                    Terms & Condition
                                </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/shipping"
                                    // target="_blank"
                                    className={classes.block}
                                >
                                    Shipping
                                </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/return-policy"
                                    // target="_blank"
                                    className={classes.block}
                                >
                                    Return Policy
                                </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/refund-policy"
                                    // target="_blank"
                                    className={classes.block}
                                >
                                    Refund Policy
                                </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/license"
                                    // target="_blank"
                                    className={classes.block}
                                >
                                    license
                                </a>
                            </ListItem>
                            {/* <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/offline-product-list"
                                    className={classes.block}
                                >
                                    Offline Product
                                </a>
                            </ListItem> */}
                            {/* <ListItem className={classes.inlineBlock}>
                                    <a
                                        href="/old-offline-product-list"
                                        className={classes.block}
                                    >
                                        Old Offline Product
                                    </a>
                                </ListItem> */}
                            {/* <ListItem className={classes.inlineBlock}>
                                    <a
                                        href="/old-bangles-making"
                                        // target="_blank"
                                        className={classes.block}
                                    >
                                        old Making charge
                                    </a>
                                </ListItem> */}
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/careers"
                                    // target="_blank"
                                    className={classes.block}
                                >
                                    Careers
                                </a>
                            </ListItem>
                            {/* <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/product-stock"
                                    // target="_blank"
                                    className={classes.block}
                                >
                                    Product Stock
                                </a>
                            </ListItem> */}
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/contact-us"
                                    className={classes.block}
                                >
                                    Contact Us
                                </a>
                            </ListItem>
                        </List>
                    </div>
                    <div className={classes.right}>
                        &copy; {1900 + new Date().getYear()} {" "}
                        <a
                            // href="https://www.creative-tim.com?ref=mkpr-footer-components"
                            target="_blank"
                            className={classes.aClasses}
                        >
                            Bangles.org.in
                        </a>
                    </div>
                </div>
            }
        />
    );
}
