/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import BanglesHeader from "views/BanglesPage/Section/BanglesHeader";
import BanglesHeaderLink from "views/BanglesPage/Section/BanglesHeaderLink";
import BanglesFooter from "views/BanglesPage/Section/BanglesFooter"
// import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import ShippingDescription from "views/BanglesPage/Section/ShippingDescription";
// import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
// import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import aboutUsStyl from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(aboutUsStyl);

export default function Shipping() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
        <Helmet>
        <title>Shipping</title>
        <meta
          name="description"
          content="Bangles.Org Shipping If you are not satisfied with your purchase, you can return the product and get a full refund or exchange the product for another one, be it similar or 
          Product return will be accepted in case of damage on receipt or difference in product design from that on the website.
          "
        />
        <meta name="keywords" content="Bangles.Org Shipping Policy" />
      </Helmet>
      <BanglesHeader
        brand="Home"
        links={<BanglesHeaderLink dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/bg2.jpg").default}
        filter="dark"
        small
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={10}
              sm={10}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1 style={{color: "#fff", fontFamily: "Tahoma", fontWeight: "bold"}}>Shipping</h1>
              <h4 style={{color: "#fff"}}>
              Your satisfaction important to us...
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ShippingDescription/>
          {/* <SectionTeam /> */}
          {/* <SectionOffice /> */}
          <br />
        </div>
      </div>
      <BanglesFooter />
    </div>
  );
}