import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.10.0";

import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import BanglesPage from "views/BanglesPage/BanglesPage.js";
import BanglesContact from "views/BanglesPage/Section/BanglesContact";
import BanglesContacts from "views/BanglesPage/Section/BanglesContacts";
import BanglesOverview from "views/BanglesPage/Section/BanglesOverview";
import BanglesCategory from "views/BanglesPage/Section/BanglesCategory";
import BanglesProject from "views/BanglesPage/Section/BanglesProject";
import BanglesComponent from "views/BanglesPage/Section/BanglesComponent";
import BanglesProduct from "views/BanglesPage/BanglesProduct";
import BangProductList from "views/BanglesPage/BangProductList";
import BanglesProductListOld from "views/BanglesPage/BanglesProductListOld";
import OfflineBangProductList from "views/BanglesPage/OfflineBangProductList";
import OfflineBangProductListOld from "views/BanglesPage/OfflineBangProductListOld";
import banglesSizeGuide from "views/BanglesPage/Section/BanglesSizeGuide";
import SizeMMlist from "views/BanglesPage/SizeMMlist";
import BanglesSignUp from "views/BanglesPage/BanglesSignUp";
import BanglesLogin from "views/BanglesPage/BanglesLogin";
import EditProfile from "views/BanglesPage/Profile/EditProfile";
import AddAddress from "views/BanglesPage/Profile/AddAddress";
import UpdateAddress from "views/BanglesPage/Profile/UpdateAddress";
import GeneratePassword from "views/BanglesPage/Profile/GeneratePassword";
import EorderForm from "views/BanglesPage/Cart/EorderForm";
import FinalCart from "views/BanglesPage/Cart/CartView";
import ErrorPage from "views/ErrorPage/ErrorPage.js";
import Igs from "views/Igs/Igs.js";
import BanglesMaking from "views/BanglesPage/Section/BanglesMaking.js";
import BanglesMakingOld from "views/BanglesPage/Section/BanglesMakingOld";
import CartShippingAddress from "views/BanglesPage/Cart/CartShippingAddress";
import AddCart from "views/BanglesPage/Cart/AddCart";
import OrderView from "views/BanglesPage/Cart/OrderView";
import OrderViewPrint from "views/BanglesPage/Cart/OrderViewPrint.js"
import MyOrder from "views/BanglesPage/Cart/MyOrder";
import PaymentRecieved from "views/BanglesPage/Cart/PaymentRecieved";
import CareerField from "views/BanglesPage/Section/CareerField";
import PrivacyPolicy from "views/BanglesPage/Section/PrivacyPolicy";
import ReturnAmount from "views/BanglesPage/Section/ReturnAmount.js";
import ReturnPolicy from "views/BanglesPage/Section/ReturnPolicy";
import RefundPolicy from "views/BanglesPage/Section/RefundPolicy";
import TermCondition from "views/BanglesPage/Section/Term&Condition";
import Shipping from "views/BanglesPage/Section/Shipping";
import License from "views/BanglesPage/Section/License";
import ProductStock from "views/BanglesPage/ProductStock";
import OrderStock from "views/BanglesPage/OrderStock";
import App from "views/Main/app.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/about-us" component={AboutUsPage} />
      <Route path="/return" component={ReturnAmount} />
      <Route path="/error-page" component={ErrorPage} />
      <Route path="/contact-us" component={BanglesContacts} />
      <Route path="/customer" component={BanglesOverview} />
      <Route path="/plating-category" component={BanglesCategory} />
      <Route path="/bangles-category" component={BanglesProject} />
      <Route path="bangles-component" component={BanglesComponent} />
      <Route path="/bangles-product" component={BanglesProduct} />
      <Route path="/product-list" component={BangProductList} />
      <Route path="/offline-product-list" component={OfflineBangProductList} />
      <Route path="/bangles-size-mm-list" component={SizeMMlist} />
      <Route path="/bangles-size-guide" component={banglesSizeGuide} />
      <Route path="/bangles-sign-up" component={BanglesSignUp} />
      <Route path="/bangles-login" component={BanglesLogin} />
      <Route path="/edit-profile" component={EditProfile} />
      <Route path="/add-address" component={AddAddress} /> 
      <Route path="/edit-update-address" component={UpdateAddress} />
      <Route path="/edit-password" component={GeneratePassword} />
      <Route path="/cart" component={EorderForm} />
      <Route path="/cart-view" component={FinalCart} />
      <Route path={`/igs`} component={Igs} />
      <Route path="/bangles-making" component={BanglesMaking} />
      <Route path="/old-bangles-making" component={BanglesMakingOld} />
      <Route path="/old-product-list" component={BanglesProductListOld} />
      <Route path="/old-offline-product-list" component={OfflineBangProductListOld} />
      <Route path="/cart-shipping-address" component={CartShippingAddress} />
      <Route path="/add-cart" component={AddCart} />
      <Route path="/order-view" component={OrderView} />
      <Route path="/order-view-print" component={OrderViewPrint} />
      <Route path="/my-order" component={MyOrder} />
      <Route path="/payment-recieved" component={PaymentRecieved} />
      <Route path="/careers" component={CareerField} />
      <Route path="/privacyPolicy" component={PrivacyPolicy} />
      <Route path="/return-policy" component={ReturnPolicy} />
      <Route path="/shipping" component={Shipping} />
      <Route path="/refund-policy" component={RefundPolicy} />
      <Route path="/terms-and-conditions" component={TermCondition} />
      <Route path="/license" component={License} />
      <Route path="/todays-deal" component={ProductStock} />
      <Route path="/order-stock" component={OrderStock} />
      <Route path="/app" component={App} />
      <Route path="/" component={BanglesPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
